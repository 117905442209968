import React, { useEffect, useState } from 'react';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { getResourceText } from '../lib/language';
import Loading, { LoadingSpinner } from './loading';

export const formatGoogleSlidesURL = (url) =>
  `${url?.endsWith('/') ? url.substring(0, url.length - 1) : url}/embed?rm=minimal&start=false&loop=false`;
export const formatYoutubeURL = (url) => {
  const youtubeParams = url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .reduce((acc, urlParameter) => {
      const [key, value] = urlParameter.split('=');
      return { ...acc, [key]: value };
    }, {});

  let embeddedYoutubeUrl = `https://www.youtube.com/embed/${youtubeParams['v']}?fs=0&modestbranding=1`;
  const removeSuffix = (value, suffix) => {
    if (value.endsWith(suffix)) {
      return value.slice(0, -suffix.length);
    }
    return value;
  };

  if (youtubeParams.t) {
    embeddedYoutubeUrl = `${embeddedYoutubeUrl}&start=${removeSuffix(youtubeParams.t, 's')}`;
  }
  return embeddedYoutubeUrl;
};

const IntersectableLesson = ({ url }) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && entry?.isIntersecting) {
      setIsLoaded(true);
    }
  }, [entry?.isIntersecting, isLoaded]);

  return (
    <div className="lesson-resource-container" ref={ref}>
      <div className="lesson-resource-container__lesson-resource-overlay" />
      {isLoaded ? (
        <iframe src={url} className="lesson-resource-container__lesson-resource" title={`Lesson resource: ${url}`} />
      ) : null}
      <Loading css="lesson-resource-container__loading">
        <LoadingSpinner />
      </Loading>
    </div>
  );
};

const LessonView = ({ googleSlidesURL, youtubeURL }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (googleSlidesURL) {
      setUrl(formatGoogleSlidesURL(googleSlidesURL));
    } else if (youtubeURL) {
      setUrl(formatYoutubeURL(youtubeURL));
    }
  }, [googleSlidesURL, youtubeURL]);

  return url ? (
    <IntersectableLesson url={url} />
  ) : (
    <div className="lesson-resource-container lesson-resource-container--no-lesson-resource error">
      {getResourceText('noLessonResource')}
    </div>
  );
};

export default LessonView;
